import { API, METHODS } from '../../constants';
import { fetchData } from '../common/apiCalling';

export const getAllHiringClient = ({ offset, limit, search }) => {
    let url = `${API.HIRING_CLIENTS}${API.ALL}?offset=${offset}&limit=${limit}`;
    if (search) {
        url = `${url}&search=${encodeURIComponent(search)}`;
    }
    return fetchData({ method: METHODS.GET, url });
};

export const addNewAccountHcPlatform = ({ body }) => {
    const url = `${API.ACCOUNT_HC_PLATFORM}`;
    return fetchData({ body, method: METHODS.POST, url });
};

export const deleteAccountHcPlatform = ({ body }) => {
    const url = `${API.ACCOUNT_HC_PLATFORM}`;
    return fetchData({ body, method: METHODS.DELETE, url });
};

export const createHiringCLient = ({ body }) => {
    const url = `${API.HIRING_CLIENTS}`;
    return fetchData({ body, method: METHODS.POST, url });
};

export const updateHiringClientOrder = ({ data }) => {
    const url = `${API.HIRING_CLIENTS}${API.ORDER}`;
    return fetchData({ method: METHODS.PUT, url, body: { data } });
};
export const updateHiringClientName = ({ data, id }) => {
    const url = `${API.HIRING_CLIENTS}/${id}`;
    return fetchData({ method: METHODS.PUT, url, body: data });
};
