import React, { useEffect, useState } from 'react';
import { BgIcon } from '../../../assets/svg';
import { getApplicationOverview } from '../../../utils';
import { popup } from '../../../utils/common';
import { OVERVIEW } from '../../../constants';
import { CustomDropdown } from '../../shared';
import { registrationAndDocumentDropdownOptions } from '../../../constants/selectOptions';

export default function OverView() {
    const [overview, setOverview] = useState({});
    const [newRegistrationRange, setNewRegistrationRange] = useState(
        registrationAndDocumentDropdownOptions[0],
    );
    const [newDocumentRange, setNewDocumentRange] = useState(
        registrationAndDocumentDropdownOptions[0],
    );
    const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
    const [isDocumentDropdownOpen, setIsDocumentDropdownOpen] = useState(false);
    const fetchOverview = () => {
        getApplicationOverview({
            newDocumentRange: newDocumentRange.value,
            newRegistrationRange: newRegistrationRange.value,
        })
            .then((response) => {
                if (response?.data) setOverview(response.data[0]);
            })
            .catch((error) => {
                popup('error', error.message);
            });
    };

    useEffect(() => {
        fetchOverview();
    }, [newRegistrationRange, newDocumentRange]);

    return (
        <div className='dashboard-header'>
            <h2 className='title mb-lg-4 mb-2'>{OVERVIEW.TITLE}</h2>
            <div className='row'>
                <div className='col-md-4 custom-col mb-lg-0 mb-1'>
                    <div className='box-container h-100 p-lg-4 p-md-3 p-2 bg-white d-flex align-items-start justify-content-start flex-column'>
                        <div className='box-title d-flex align-items-center'>
                            <BgIcon />
                            <p className='mb-0'>{OVERVIEW.NEW_REGISTRATIONS}</p>
                        </div>
                        <div className='box-num-count d-flex align-items-center justify-content-between w-100'>
                            <h4 className='mb-0 me-2'>{overview.new_registrations}</h4>
                            <CustomDropdown
                                options={registrationAndDocumentDropdownOptions}
                                handleOptionSelect={(option) => {
                                    setNewRegistrationRange(option);
                                    setIsAccountDropdownOpen(false);
                                }}
                                isDropdownOpen={isAccountDropdownOpen}
                                setIsDropdownOpen={setIsAccountDropdownOpen}
                                selectedOption={newRegistrationRange.id}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-md-4 custom-col mb-lg-0 mb-1'>
                    <div className='box-container h-100 p-lg-4 p-md-3 p-2 bg-white d-flex align-items-start justify-content-start flex-column'>
                        <div className='box-title d-flex align-items-center'>
                            <BgIcon />
                            <p className='mb-0'>{OVERVIEW.DOCUMENTS_CREATED}</p>
                        </div>
                        <div className='box-num-count d-flex align-items-center justify-content-between w-100'>
                            <h4 className='mb-0 me-2'>{overview.new_docs_created}</h4>
                            <CustomDropdown
                                options={registrationAndDocumentDropdownOptions}
                                handleOptionSelect={(option) => {
                                    setNewDocumentRange(option);
                                    setIsDocumentDropdownOpen(false);
                                }}
                                isDropdownOpen={isDocumentDropdownOpen}
                                setIsDropdownOpen={setIsDocumentDropdownOpen}
                                selectedOption={newDocumentRange.id}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
