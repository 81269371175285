import { AUTH, REGEX } from './strings';
import { VALIDATION } from './messages';

export const SIGNUP_VALIDATION = {
    SCHEMA: {
        firstName: {
            regex: REGEX.NAME,
            message: VALIDATION.INVALID_FIRST_NAME,
            requiredMessage: VALIDATION.FIRST_NAME_REQUIRED,
        },
        lastName: {
            regex: REGEX.NAME,
            message: VALIDATION.INVALID_LAST_NAME,
            requiredMessage: VALIDATION.LAST_NAME_REQUIRED,
        },
        email: {
            regex: REGEX.EMAIL,
            message: VALIDATION.INVALID_EMAIL,
            requiredMessage: VALIDATION.EMAIL_REQUIRED,
        },
        account: {
            regex: REGEX.ACCOUNT_NAME,
            message: VALIDATION.ACCOUNT_NAME,
            requiredMessage: VALIDATION.ACCOUNT_NAME_REQUIRED,
        },
        password: {
            regex: REGEX.STRONG_PASSWORD,
            message: VALIDATION.STRONG_PASSWORD_REQUIRED,
            requiredMessage: VALIDATION.PASSWORD_REQUIRED,
        },
        confirmPassword: {
            matchWith: 'password',
            regex: REGEX.STRONG_PASSWORD,
            message: VALIDATION.PASSWORD_DONT_MATCH,
            requiredMessage: VALIDATION.CONFIRM_PASSWORD_REQUIRED,
        },
    },
    PASSWORD_REQUIREMENT: [
        { regex: REGEX.SMALL_CHARACTER, label: AUTH.SMALL_CHARACTER },
        { regex: REGEX.CAPITAL_CASE_CHARACTER, label: AUTH.CAPITAL_CASE_CHARACTER },
        { regex: REGEX.NUMERIC_CHARACTER, label: AUTH.NUMERIC_CHARACTER },
        { regex: REGEX.SPECIAL_CHARACTER, label: AUTH.SPECIAL_CHARACTER },
        { regex: REGEX.MINIMUM_LENGTH_10, label: AUTH.MINIMUM_LENGTH_10 },
        { regex: REGEX.MAXIMUM_LENGTH_16, label: AUTH.MAXIMUM_LENGTH_16 }, // HOTFIX-PCM-195
    ],
};

export const LOGIN_VALIDATION = {
    SCHEMA: {
        email: {
            regex: REGEX.EMAIL,
            message: VALIDATION.INVALID_EMAIL,
            requiredMessage: VALIDATION.EMAIL_REQUIRED,
        },
        password: {
            requiredMessage: VALIDATION.PASSWORD_REQUIRED,
        },
    },
};
