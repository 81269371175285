import React, { useEffect, useState } from 'react';
import { ButtonComponent } from '../Buttons/ButtonComponent';
import {
    HIDDEN_HIRING_CLIENT_COLUMN,
    HIDDEN_HIRING_CLIENTS,
    SCORECARD_REPORT,
} from '../../../constants';
import { useDebounce, usePagination } from '../../../hooks';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility, setSelectedHiringClients } from '../../../redux';
import { popup } from '../../../utils';
import { Table } from '../Table';
import {
    getHiddenHiringClients,
    showHiringClients,
} from '../../../utils/apiServices/hideHiringClientApi';
import { SearchIcon } from '../../../assets/svg';
import { useSelector } from 'react-redux';

const UnhideHiringClientModal = ({ showModal, setShowModal, accountId, debouncedApiCall }) => {
    const [hiddenHClData, setHiddenHClData] = useState(undefined);
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();
    const pagination = usePagination(hiddenHClData?.data.length, hiddenHClData?.count);
    const { selectedHiringClients, selectedAccount } = useSelector((s) => s.accountArray);

    const getHiddenHCByAccountId = useDebounce(async () => {
        try {
            const response = await getHiddenHiringClients({
                accountId,
                offset: pagination.offset,
                limit: pagination.limit,
                search: search,
            });
            setHiddenHClData({ data: response.data, count: response.count });
        } catch (error) {
            popup('error', error.message);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    }, 1000);

    const unhideHiringClient = useDebounce(async () => {
        dispatch(setLoaderVisibility(true));
        try {
            await showHiringClients({
                accountId: selectedAccount,
                hiringClientIds: selectedHiringClients,
            });
            popup('success', 'Hiring clients unhidden successfully');
            setShowModal(false);
            debouncedApiCall();
            dispatch(setSelectedHiringClients([]));
        } catch (error) {
            popup('error', error.message);
        } finally {
            dispatch(setLoaderVisibility(false));
        }
    }, 1000);

    useEffect(() => {
        if (accountId) {
            !search && dispatch(setLoaderVisibility(true));
            getHiddenHCByAccountId();
        }
    }, [accountId, pagination.limit, pagination.offset, search]);
    return (
        <div
            className={`modal custom-modal-doc-cato fade ${showModal ? 'show' : ''}`}
            tabIndex={-1}
            aria-labelledby={HIDDEN_HIRING_CLIENTS.MODAL.NAME}
            aria-hidden={!showModal}
            role='dialog'
            style={{ display: showModal ? 'block' : 'none', zIndex: 9999 }}
        >
            <div className='modal-dialog w-100' style={{ minWidth: '620px' }}>
                <div className='modal-content border-0'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <span className='head-title-edit'>
                            {HIDDEN_HIRING_CLIENTS.MODAL.HEADING}
                        </span>
                        <div className='custom-search-box d-flex align-items-center justify-content-start'>
                            <button className='bg-white p-0 border-0 d-flex align-items-center'>
                                <SearchIcon />
                            </button>
                            <input
                                type='text'
                                maxLength={40}
                                className='search-area border-0 w-100 py-1'
                                placeholder={
                                    SCORECARD_REPORT.SCORE_CARD_DETAILS_PAGE.SEARCH_BAR_PLACEHOLDER
                                }
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                        <ButtonComponent
                            clickHandler={() => {
                                dispatch(setSelectedHiringClients([]));
                                setShowModal(false);
                            }}
                            className='btn-close'
                        ></ButtonComponent>
                    </div>
                    <div className='modal-body p-0 pt-4'>
                        {!!hiddenHClData && (
                            <Table
                                columns={HIDDEN_HIRING_CLIENT_COLUMN}
                                data={hiddenHClData?.data}
                                pagination={pagination}
                            />
                        )}
                    </div>

                    <div className='d-flex align-items-center justify-content-between modal-btn mt-3'>
                        <span
                            className='mt-1'
                            style={{ color: '#738da6', fontSize: '14px' }}
                        >{`${selectedHiringClients.length} selected`}</span>
                        <button
                            type='submit'
                            onClick={unhideHiringClient}
                            className='ssc-primary-green-btn py-0 w-auto'
                            disabled={selectedHiringClients.length === 0}
                        >
                            {HIDDEN_HIRING_CLIENTS.BUTTONS.SAVE}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UnhideHiringClientModal;
